import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { areaElementClasses } from '@mui/x-charts/LineChart';

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.3} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

const ExecutionTrend = ({ executionData, timeFilter, totalExecutions }) => {
  const theme = useTheme();

  // Calculate trend using linear regression
  const calculateTrend = (data) => {
    const n = data.length;
    if (n < 2) return { slope: 0, percentage: 0 };

    let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;
    data.forEach((y, x) => {
      sumX += x;
      sumY += y;
      sumXY += x * y;
      sumX2 += x * x;
    });

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    const startY = data[0];
    const predictedEndY = slope * (n - 1) + intercept;

    // Calculate percentage change based on the regression line
    const percentageChange = startY !== 0 ? ((predictedEndY - startY) / startY) * 100 : 0;

    return { slope, percentage: percentageChange };
  };

  const { slope, percentage } = calculateTrend(executionData);

  const trend = slope > 0 ? 'up' : slope < 0 ? 'down' : 'neutral';

  const trendColors = {
    up: theme.palette.success.main,
    down: theme.palette.error.main,
    neutral: theme.palette.grey[500],
  };

  const labelColors = {
    up: 'success',
    down: 'error',
    neutral: 'default',
  };

  const color = labelColors[trend];
  const chartColor = trendColors[trend];

  const trendValue = `${percentage > 0 ? '+' : ''}${percentage.toFixed(1)}%`;

  return (
    <Card
      elevation={3}
      sx={{
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Execution Trend
        </Typography>
        <Stack
          direction="column"
          sx={{ justifyContent: 'space-between', flexGrow: 1, gap: 1 }}
        >
          <Stack sx={{ justifyContent: 'space-between' }}>
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="h4" component="p">
                {totalExecutions}
              </Typography>
              <Chip size="small" color={color} label={trendValue} />
            </Stack>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Past {timeFilter.charAt(0).toUpperCase() + timeFilter.slice(1)}
            </Typography>
          </Stack>
          <Box sx={{ width: '100%', height: 50 }}>
            <SparkLineChart
              colors={[chartColor]}
              data={executionData}
              area
              showHighlight
              showTooltip
              sx={{
                [`& .${areaElementClasses.root}`]: {
                  fill: `url(#area-gradient-executions)`,
                },
              }}
            >
              <AreaGradient color={chartColor} id="area-gradient-executions" />
            </SparkLineChart>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ExecutionTrend;
