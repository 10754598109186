/* eslint-disable */

import React, { useState, useEffect } from 'react';
import {
  Box, Typography, useTheme,
  Tooltip, Alert, Snackbar, Paper,
  FormControl, InputLabel, Select, MenuItem,
  IconButton, Button, Dialog, DialogTitle,
  DialogContent, DialogActions
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { fetchWithAuth } from '../utils/api';
import { PROCESSING_TYPES } from '../constants/processingTypes';
import { EVENT_STATUS_TYPES } from '../constants/eventStatusTypes';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';

function ProcessingEvents() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [timeFilter, setTimeFilter] = useState('week');
  const [statusFilter, setStatusFilter] = useState(['FAILURE', 'PENDING']);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [tradePartners, setTradePartners] = useState([]);
  const [tempFilters, setTempFilters] = useState({
    timeFilter: 'week',
    statusFilter: ['FAILURE', 'PENDING'],
    customerId: '',
    tradePartnerId: '',
    processingType: ''
  });
  const theme = useTheme();

  useEffect(() => {
    fetchCustomers();
    fetchTradePartners();
  }, []);

  const fetchCustomers = async () => {
    try {
      const data = await fetchWithAuth('/customer');
      setCustomers(data);
    } catch (err) {
      console.error('Error fetching customers:', err);
    }
  };

  const fetchTradePartners = async () => {
    try {
      const data = await fetchWithAuth('/tradePartner');
      setTradePartners(data);
    } catch (err) {
      console.error('Error fetching trade partners:', err);
    }
  };

  const getTimeRange = (filter) => {
    const now = new Date();
    let createdAfter;

    switch (filter) {
      case 'year':
        createdAfter = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      case 'month':
        createdAfter = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case 'week':
        createdAfter = new Date(now.setDate(now.getDate() - 7));
        break;
      case 'day':
        createdAfter = new Date(now.setDate(now.getDate() - 1));
        break;
      case 'hour':
        createdAfter = new Date(now.setHours(now.getHours() - 1));
        break;
      default:
        createdAfter = new Date(now.setDate(now.getDate() - 7));
    }

    return {
      createdAfter: createdAfter.toISOString().split('.')[0] + 'Z'
    };
  };

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const { createdAfter } = getTimeRange(timeFilter);

      const params = new URLSearchParams({
        createdAfter: createdAfter,
        page: page.toString(),
        page_size: pageSize.toString()
      });

      if (tempFilters.customerId) {
        params.append('customerId', tempFilters.customerId);
      }

      if (tempFilters.tradePartnerId) {
        params.append('tradePartnerId', tempFilters.tradePartnerId);
      }

      if (tempFilters.processingType) {
        params.append('processingType', tempFilters.processingType);
      }

      statusFilter.forEach(status => {
        params.append('status', status);
      });

      const response = await fetchWithAuth(`/processingEvent?${params}`);

      if (Array.isArray(response)) {
        const uniqueEvents = response.reduce((acc, event) => {
          if (!acc.some(e => e.id === event.id)) {
            acc.push({
              ...event,
              id: event.id
            });
          }
          return acc;
        }, []);

        setEvents(uniqueEvents);
        setTotalRows(uniqueEvents.length);
        setError(null);
      } else {
        setEvents([]);
        setTotalRows(0);
        setError('Received unexpected data format from server');
      }
    } catch (error) {
      setEvents([]);
      setTotalRows(0);
      setError('Failed to fetch processing events. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [timeFilter, statusFilter, page, pageSize, tempFilters.customerId, tempFilters.tradePartnerId, tempFilters.processingType]);

  const handleFilterDialogOpen = () => {
    setTempFilters({
      timeFilter,
      statusFilter,
      customerId: tempFilters.customerId,
      tradePartnerId: tempFilters.tradePartnerId,
      processingType: tempFilters.processingType
    });
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const handleApplyFilters = () => {
    setTimeFilter(tempFilters.timeFilter);
    setStatusFilter(tempFilters.statusFilter);
    setPage(1);
    setFilterDialogOpen(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  const handleRetry = async (id) => {
    try {
      setLoading(true);
      console.log(`Retrying processing event with ID: ${id}`);

      const event = events.find(e => e.id === id);
      const sourceParam = event?.processing_type === 'formProductDetection' ? '&source=form' : '';

      const response = await fetchWithAuth(`/execution/${id}?processingEventId=${id}${sourceParam}`, {
        method: 'POST'
      });
      console.log('Retry response:', response);

      setSnackbar({
        open: true,
        message: 'Processing event retry initiated successfully',
        severity: 'success'
      });
      fetchEvents(); // Refresh the list
    } catch (error) {
      console.error('Error retrying processing event:', error);
      setSnackbar({
        open: true,
        message: 'Failed to retry processing event',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const CellWithTooltip = ({ value }) => (
    <Tooltip title={value} arrow>
      <Box sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        {value}
      </Box>
    </Tooltip>
  );

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'created_at', headerName: 'Created At', flex: 0.5, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'customer_id', headerName: 'Customer ID', flex: 0.5, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'trade_partner_id', headerName: 'Trade Partner ID', flex: 0.5, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'processing_type', headerName: 'Processing Type', flex: 0.5, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'request_body', headerName: 'Request Body', flex: 1, renderCell: (params) => <CellWithTooltip value={JSON.stringify(params.value)} /> },
    { field: 'status', headerName: 'Status', flex: 0.5, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title="Retry">
          <IconButton
            onClick={() => handleRetry(params.row.id)}
            size="small"
            color="primary"
          >
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box sx={{ height: '100%', width: '100%', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Processing Events
        </Typography>

        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={handleFilterDialogOpen}
        >
          Filters
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper sx={{ height: 'calc(100vh - 250px)', width: '100%' }}>
        <DataGrid
          rows={events}
          columns={columns}
          pagination
          page={page - 1}
          pageSize={pageSize}
          rowCount={totalRows}
          rowsPerPageOptions={[25, 50, 100]}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          loading={loading}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
          }}
        />
      </Paper>

      <Dialog open={filterDialogOpen} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Processing Events</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Time Range</InputLabel>
            <Select
              value={tempFilters.timeFilter}
              label="Time Range"
              onChange={(e) => setTempFilters({ ...tempFilters, timeFilter: e.target.value })}
            >
              <MenuItem value="hour">Last Hour</MenuItem>
              <MenuItem value="day">Last Day</MenuItem>
              <MenuItem value="week">Last Week</MenuItem>
              <MenuItem value="month">Last Month</MenuItem>
              <MenuItem value="year">Last Year</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Customer ID</InputLabel>
            <Select
              value={tempFilters.customerId}
              label="Customer ID"
              onChange={(e) => setTempFilters({ ...tempFilters, customerId: e.target.value })}
            >
              <MenuItem value="">All</MenuItem>
              {customers.map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {`${customer.id} - ${customer.name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Trade Partner ID</InputLabel>
            <Select
              value={tempFilters.tradePartnerId}
              label="Trade Partner ID"
              onChange={(e) => setTempFilters({ ...tempFilters, tradePartnerId: e.target.value })}
            >
              <MenuItem value="">All</MenuItem>
              {tradePartners.map((partner) => (
                <MenuItem key={partner.id} value={partner.id}>
                  {`${partner.id} - ${partner.name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Processing Type</InputLabel>
            <Select>
              <MenuItem value="">All</MenuItem>
              {PROCESSING_TYPES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Status</InputLabel>
            <Select
              multiple
              value={tempFilters.statusFilter || ['FAILURE', 'PENDING']}
              onChange={(e) => setTempFilters({ ...tempFilters, statusFilter: e.target.value })}
              label="Status"
            >
              {EVENT_STATUS_TYPES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                {label}
                </MenuItem>
            ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFilterDialogClose}>Cancel</Button>
          <Button onClick={handleApplyFilters} variant="contained">Apply</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ProcessingEvents;
