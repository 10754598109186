import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/api';
import {
  Box, Typography, Button, Modal, TextField, Select, MenuItem, FormControl, InputLabel,
  IconButton, Tooltip, CircularProgress, Alert, Snackbar, useTheme
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import Chip from '@mui/material/Chip';

function ProcessingConnectionsManagement() {
  const theme = useTheme();
  const [processingConnections, setProcessingConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentConnection, setCurrentConnection] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    conn_type: '',
    description: '',
    customer_id: '',
    trade_partner_id: '',
    credentials: {},
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [customers, setCustomers] = useState([]);
  const [tradePartners, setTradePartners] = useState([]);

  useEffect(() => {
    fetchProcessingConnections();
    fetchCustomers();
    fetchTradePartners();
  }, []);

  const fetchProcessingConnections = async () => {
    try {
      setLoading(true);
      const data = await fetchWithAuth('/processingConnection');
      setProcessingConnections(data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching processing connections:', err);
      setError(`Failed to fetch processing connections: ${err.message}`);
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      const data = await fetchWithAuth('/customer');
      setCustomers(data);
    } catch (err) {
      console.error('Error fetching customers:', err);
    }
  };

  const fetchTradePartners = async () => {
    try {
      const data = await fetchWithAuth('/tradePartner');
      setTradePartners(data);
    } catch (err) {
      console.error('Error fetching trade partners:', err);
    }
  };

  const handleProcessingConnection = async (isCreate) => {
    try {
      const method = isCreate ? 'POST' : 'PATCH';
      const url = isCreate ? '/processingConnection' : `/processingConnection/${currentConnection.id}`;
      await fetchWithAuth(url, {
        method: method,
        body: JSON.stringify(formData),
      });
      setOpenModal(false);
      fetchProcessingConnections();
      setSnackbar({ open: true, message: `Processing connection ${isCreate ? 'created' : 'updated'} successfully`, severity: 'success' });
    } catch (err) {
      setSnackbar({ open: true, message: `Failed to ${isCreate ? 'create' : 'update'} processing connection`, severity: 'error' });
    }
  };

  const deleteProcessingConnection = async (id) => {
    if (window.confirm('Are you sure you want to delete this processing connection?')) {
      try {
        await fetchWithAuth(`/processingConnection/${id}`, { method: 'DELETE' });
        fetchProcessingConnections();
        setSnackbar({ open: true, message: 'Processing connection deleted successfully', severity: 'success' });
      } catch (err) {
        setSnackbar({ open: true, message: 'Failed to delete processing connection', severity: 'error' });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCredentialsChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      credentials: {
        ...formData.credentials,
        [name]: value,
      },
    });
  };

  const handleEditClick = (params) => {
    setCurrentConnection(params.row);
    setFormData({
      name: params.row.name || '',
      conn_type: params.row.conn_type || '',
      description: params.row.description || '',
      customer_id: params.row.customer_id || '',
      trade_partner_id: params.row.trade_partner_id || '',
      credentials: params.row.credentials || {},
    });
    setOpenModal(true);
  };

  const renderCredentialsFields = () => {
    switch (formData.conn_type) {
      case 'database':
        return (
          <>
            <TextField fullWidth margin="normal" name="scheme" label="Scheme" value={formData.credentials.scheme || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="host" label="Host" value={formData.credentials.host || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="port" label="Port" value={formData.credentials.port || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="username" label="Username" value={formData.credentials.username || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="password" label="Password" type="password" value={formData.credentials.password || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="database" label="Database" value={formData.credentials.database || ''} onChange={handleCredentialsChange} />
          </>
        );
      case 'api_key':
        return (
          <>
            <TextField fullWidth margin="normal" name="api_key" label="API Key" value={formData.credentials.api_key || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="url" label="URL" value={formData.credentials.url || ''} onChange={handleCredentialsChange} />
          </>
        );
      case 'oauth':
        return (
          <>
            <TextField fullWidth margin="normal" name="client_id" label="Client ID" value={formData.credentials.client_id || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="client_secret" label="Client Secret" type="password" value={formData.credentials.client_secret || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="authorization_url" label="Authorization URL" value={formData.credentials.authorization_url || ''} onChange={handleCredentialsChange} />
          </>
        );
      case 'sftp':
        return (
          <>
            <TextField fullWidth margin="normal" name="host" label="Host" value={formData.credentials.host || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="port" label="Port" value={formData.credentials.port || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="username" label="Username" value={formData.credentials.username || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="password" label="Password" type="password" value={formData.credentials.password || ''} onChange={handleCredentialsChange} />
          </>
        );
      case 'private_key':
        return (
          <>
            <TextField fullWidth margin="normal" name="private_key" label="Private Key" multiline rows={4} value={formData.credentials.private_key || ''} onChange={handleCredentialsChange} />
            <TextField fullWidth margin="normal" name="consumer_key" label="Consumer Key" value={formData.credentials.consumer_key || ''} onChange={handleCredentialsChange} />
          </>
        );
        case 'client_id_secret':
          return (
            <>
              <TextField fullWidth margin="normal" name="client_id" label="Client Id" multiline rows={4} value={formData.credentials.client_id || ''} onChange={handleCredentialsChange} />
              <TextField fullWidth margin="normal" name="client_secret" label="Client Secret" multiline rows={4} value={formData.credentials.client_secret || ''} onChange={handleCredentialsChange} />
              <TextField fullWidth margin="normal" name="url" label="URL" multiline rows={4} value={formData.credentials.url || ''} onChange={handleCredentialsChange} />
          </>
        );
        case 'token':
          return (
            <>
              <TextField fullWidth margin="normal" name="url" label="URL" multiline rows={4} value={formData.credentials.url || ''} onChange={handleCredentialsChange} />
              <TextField fullWidth margin="normal" name="token" label="Token" multiline rows={4} value={formData.credentials.token || ''} onChange={handleCredentialsChange} />
          </>
        );
      default:
        return null;
    }
  };

  const renderModal = () => {
    const isCreate = !currentConnection;
    const title = isCreate ? 'Create New Processing Connection' : 'Update Processing Connection';

    return (
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: 2,
        }}>
          <Typography variant="h6" component="h2" gutterBottom>{title}</Typography>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Connection Name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Connection Type</InputLabel>
            <Select
              name="conn_type"
              value={formData.conn_type}
              onChange={handleInputChange}
            >
              <MenuItem value="database">Database</MenuItem>
              <MenuItem value="api_key">API Key</MenuItem>
              <MenuItem value="oauth">OAuth</MenuItem>
              <MenuItem value="sftp">SFTP</MenuItem>
              <MenuItem value="private_key">Private Key</MenuItem>
              <MenuItem value="client_id_secret">Client Id Secret</MenuItem>
              <MenuItem value="token">Token</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            name="description"
            label="Description"
            value={formData.description}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="customer-id-label">Customer</InputLabel>
            <Select
              labelId="customer-id-label"
              name="customer_id"
              value={formData.customer_id}
              onChange={handleInputChange}
              label="Customer"
            >
              {customers && customers.length > 0 ? (
                customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                ))
              ) : (
                <MenuItem disabled>No customers available</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="trade-partner-id-label">Trade Partner</InputLabel>
            <Select
              labelId="trade-partner-id-label"
              name="trade_partner_id"
              value={formData.trade_partner_id}
              onChange={handleInputChange}
              label="Trade Partner"
            >
              {tradePartners && tradePartners.length > 0 ? (
                tradePartners.map((tradePartner) => (
                  <MenuItem key={tradePartner.id} value={tradePartner.id}>{tradePartner.name}</MenuItem>
                ))
              ) : (
                <MenuItem disabled>No trade partners available</MenuItem>
              )}
            </Select>
          </FormControl>
          {renderCredentialsFields()}
          <Button onClick={() => handleProcessingConnection(isCreate)} variant="contained" sx={{ mt: 2 }}>
            {isCreate ? 'Create' : 'Update'}
          </Button>
        </Box>
      </Modal>
    );
  };

  const CellWithTooltip = ({ value }) => (
    <Tooltip title={value || ''} arrow>
      <Box sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        {value || ''}
      </Box>
    </Tooltip>
  );

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.5,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'conn_type',
      headerName: 'Connection Type',
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color="primary"
          variant="outlined"
          size="small"
          sx={{ fontSize: '0.75rem' }}
        />
      )
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'customer_id',
      headerName: 'Customer Name',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={customers.find(c => c.id === params.value)?.name || params.value} />
    },
    {
      field: 'trade_partner_id',
      headerName: 'Trade Partner Name',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={tradePartners.find(tp => tp.id === params.value)?.name || params.value} />
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params)}
              size="small"
              sx={{ color: theme.palette.text.primary }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => deleteProcessingConnection(params.id)}
              size="small"
              sx={{ color: theme.palette.error.main }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  if (loading) return <Box display="flex" justifyContent="center" m={4}><CircularProgress /></Box>;

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" color="text.primary">Processing Connections Management</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentConnection(null);
            setFormData({
              name: '',
              conn_type: '',
              description: '',
              customer_id: '',
              trade_partner_id: '',
              credentials: {},
            });
            setOpenModal(true);
          }}
        >
          Create New Processing Connection
        </Button>
      </Box>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Paper
        elevation={3}
        sx={{
          bgcolor: theme.palette.background.paper,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <DataGrid
          rows={processingConnections}
          columns={columns}
          autoHeight
          disableColumnResize
          disableRowSelectionOnClick
          density="compact"
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            border: 'none',
          }}
        />
      </Paper>
      {renderModal()}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ProcessingConnectionsManagement;
