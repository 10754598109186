import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, IconButton, Tooltip, useTheme, Grid } from '@mui/material';
import { ZoomIn, ZoomOut, Save, ArrowBack } from '@mui/icons-material';
import { fetchWithAuth } from '../utils/api';
import JSONEditor from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Stage, Layer, Rect, Text, Arrow, Group } from 'react-konva';

function ConfigVisualization() {
  const { configId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [config, setConfig] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateStageSize = () => {
      setStageSize({
        width: window.innerWidth - 48, // Adjust for padding
        height: 160, // Reduced height to match previous size
      });
    };

    updateStageSize();
    window.addEventListener('resize', updateStageSize);
    return () => window.removeEventListener('resize', updateStageSize);
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const data = await fetchWithAuth(`/processingConfig/${configId}`);
        setConfig(data.config);
        if (data.config && data.config.pipelines) {
          const newNodes = data.config.pipelines.flatMap((pipeline, pIndex) => {
            const stepNodes = pipeline.steps.map((step, sIndex) => ({
              id: step.id,
              name: step.id,
              type: step.type,
              stage: step.stage,
              x: sIndex * 220 + 40,
              y: pIndex * 160 + 20,  // Increased vertical spacing between pipelines
              config: step.config,
            }));

            const lastStepX = (stepNodes.length - 1) * 220 + 40;

            // Add delivery nodes
            const deliveryNodes = pipeline.delivery.map((delivery, dIndex) => ({
              id: delivery.id,
              name: delivery.id,
              type: delivery.type,
              stage: 'delivery',
              x: lastStepX + 220,  // Place delivery nodes to the right of the last step
              y: pIndex * 160 + 20 + (dIndex * 80),  // Offset vertically for multiple delivery nodes
              config: delivery.config,
            }));

            return [...stepNodes, ...deliveryNodes];
          });
          setNodes(newNodes);
        }
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, [configId]);

  useEffect(() => {
    // Calculate content size based on nodes
    if (nodes.length > 0) {
      const maxX = Math.max(...nodes.map(node => node.x + 180)); // 180 is node width
      const maxY = Math.max(...nodes.map(node => node.y + 60)); // 60 is node height
      setContentSize({ width: maxX + 40, height: maxY + 40 }); // Add some padding
    }
  }, [nodes]);

  const constrainPan = useCallback((newPan) => {
    const minX = Math.min(0, stageSize.width - contentSize.width * zoom);
    const minY = Math.min(0, stageSize.height - contentSize.height * zoom);
    return {
      x: Math.max(minX, Math.min(0, newPan.x)),
      y: Math.max(minY, Math.min(0, newPan.y)),
    };
  }, [stageSize, contentSize, zoom]);

  const renderArrows = useCallback((node, index) => {
    const nodeWidth = 180;
    const nodeHeight = 60;
    const arrows = [];

    const nextNode = nodes[index + 1];
    if (nextNode) {
      if (node.stage !== 'delivery' && nextNode.stage !== 'delivery') {
        // Arrow to the next step
        arrows.push(
          <Arrow
            key={`${node.id}-to-${nextNode.id}`}
            points={[nodeWidth, nodeHeight / 2, nodeWidth + 40, nodeHeight / 2]}
            stroke={theme.palette.divider}
            fill={theme.palette.divider}
          />
        );
      } else if (node.stage !== 'delivery' && nextNode.stage === 'delivery') {
        // Arrows to delivery nodes
        const deliveryNodes = nodes.filter(n => n.stage === 'delivery' && n.y >= node.y && n.y < node.y + 160);
        deliveryNodes.forEach((deliveryNode, dIndex) => {
          arrows.push(
            <Arrow
              key={`${node.id}-to-${deliveryNode.id}`}
              points={[
                nodeWidth,
                nodeHeight / 2,
                nodeWidth + 20,
                nodeHeight / 2,
                nodeWidth + 20,
                deliveryNode.y - node.y + nodeHeight / 2,
                nodeWidth + 40,
                deliveryNode.y - node.y + nodeHeight / 2
              ]}
              stroke={theme.palette.divider}
              fill={theme.palette.divider}
            />
          );
        });
      }
    }

    return arrows;
  }, [nodes, theme]);

  const renderNode = useCallback((node, index) => {
    const isSelected = selectedNode && selectedNode.id === node.id;
    const nodeWidth = 180;
    const nodeHeight = 60;

    return (
      <Group key={node.id} x={node.x} y={node.y}>
        <Rect
          width={nodeWidth}
          height={nodeHeight}
          fill={isSelected
            ? theme.palette.action.selected
            : theme.palette.background.paper
          }
          stroke={theme.palette.divider}
          strokeWidth={2}
          cornerRadius={8}
        />
        <Text
          text={node.id}
          width={nodeWidth}
          height={nodeHeight / 2}
          align="center"
          verticalAlign="middle"
          fontSize={12}
          fill={theme.palette.text.primary}
        />
        <Text
          text={`${node.stage} - ${node.type}`}
          width={nodeWidth}
          height={nodeHeight / 2}
          y={nodeHeight / 2}
          align="center"
          verticalAlign="middle"
          fontSize={10}
          fill={theme.palette.text.secondary}
        />
        {renderArrows(node, index)}
      </Group>
    );
  }, [selectedNode, theme, renderArrows]);

  const handleStageClick = (e) => {
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    const clickedNode = nodes.find(node => {
      const nodeRect = {
        x: node.x * zoom + stage.x(),
        y: node.y * zoom + stage.y(),
        width: 180 * zoom,
        height: 60 * zoom,
      };
      return (
        pointerPosition.x >= nodeRect.x &&
        pointerPosition.x <= nodeRect.x + nodeRect.width &&
        pointerPosition.y >= nodeRect.y &&
        pointerPosition.y <= nodeRect.y + nodeRect.height
      );
    });

    setSelectedNode(clickedNode ? {
      ...clickedNode,
      fullConfig: {
        id: clickedNode.id,
        type: clickedNode.type,
        stage: clickedNode.stage,
        config: clickedNode.config
      }
    } : null);
  };

  const handleConfigChange = (json) => {
    if (!json.error) {
      setConfig(json.jsObject);
      if (json.jsObject && json.jsObject.pipelines) {
        const newNodes = json.jsObject.pipelines.flatMap((pipeline, pIndex) => {
          const stepNodes = pipeline.steps.map((step, sIndex) => ({
            id: step.id,
            name: step.id,
            type: step.type,
            stage: step.stage,
            x: sIndex * 220 + 40,
            y: pIndex * 160 + 20,  // Increased vertical spacing between pipelines
            config: step.config,
          }));

          const deliveryNodes = pipeline.delivery.map((delivery, dIndex) => ({
            id: delivery.id,
            name: delivery.id,
            type: delivery.type,
            stage: 'delivery',
            x: (stepNodes.length - 1) * 220 + 40,
            y: pIndex * 160 + 20 + (dIndex * 80),  // Offset vertically for multiple delivery nodes
            config: delivery.config,
          }));

          return [...stepNodes, ...deliveryNodes];
        });
        setNodes(newNodes);
      }
    }
  };

  const handleNodeConfigChange = (json) => {
    if (selectedNode && !json.error) {
      const { id, type, stage, config } = json.jsObject;
      const updatedNodes = nodes.map((node) =>
        node.id === selectedNode.id
          ? { ...node, id, type, stage, config }
          : node
      );
      setNodes(updatedNodes);
      setSelectedNode({ ...selectedNode, fullConfig: json.jsObject });

      // Update the main config object
      const updatedConfig = { ...config };
      updatedConfig.pipelines[0].steps = updatedNodes.map(node => ({
        id: node.id,
        type: node.type,
        stage: node.stage,
        config: node.config,
      }));
      setConfig(updatedConfig);
    }
  };

  const handleSave = async () => {
    try {
      await fetchWithAuth(`/processingConfig/${configId}`, {
        method: 'PATCH',
        body: JSON.stringify({ config }),
      });
      alert('Configuration saved successfully');
    } catch (error) {
      console.error('Error saving config:', error);
      alert('Failed to save configuration');
    }
  };

  const handleDragEnd = (e) => {
    const newPan = constrainPan({ x: e.target.x(), y: e.target.y() });
    setPan(newPan);
    e.target.position(newPan);
  };

  const handleDragMove = (e) => {
    const newPan = constrainPan({ x: e.target.x(), y: e.target.y() });
    e.target.position(newPan);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4">Config Visualizer</Typography>
        <Box>
          <Tooltip title="Back to Processing Configs">
            <IconButton onClick={() => navigate('/processing-configs')} sx={{ mr: 1 }}>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save Configuration">
            <IconButton onClick={handleSave} color="primary">
              <Save />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Paper elevation={3} sx={{ p: 2, mb: 2, height: '160px', position: 'relative', overflow: 'hidden' }}>
          <Stage
            width={stageSize.width}
            height={stageSize.height}
            onClick={handleStageClick}
            draggable
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMove}
            scale={{ x: zoom, y: zoom }}
            x={pan.x}
            y={pan.y}
          >
            <Layer>
              {nodes.map(renderNode)}
            </Layer>
          </Stage>
        <Box sx={{ position: 'absolute', bottom: 8, right: 8, display: 'flex', gap: 1 }}>
          <Tooltip title="Zoom In">
            <IconButton onClick={() => setZoom(z => Math.min(z + 0.1, 2))} size="small">
              <ZoomIn />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom Out">
            <IconButton onClick={() => setZoom(z => Math.max(z - 0.1, 0.5))} size="small">
              <ZoomOut />
            </IconButton>
          </Tooltip>
        </Box>
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Full Configuration</Typography>
            <JSONEditor
              placeholder={config}
              locale={locale}
              height="400px"
              width="100%"
              onChange={handleConfigChange}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Selected Node Configuration</Typography>
            {selectedNode ? (
              <JSONEditor
                placeholder={selectedNode.fullConfig}
                locale={locale}
                height="400px"
                width="100%"
                onChange={handleNodeConfigChange}
              />
            ) : (
              <Typography color="text.secondary">Select a node to view its configuration</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ConfigVisualization;
