import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate, fetchWithAuth } from '../utils/api';
import {
  Button, TextField, Typography, Container, Box, CircularProgress, Alert, useTheme,
  Paper, InputAdornment, IconButton, Tooltip
} from '@mui/material';
import { LockOutlined, Visibility, VisibilityOff, Email, CheckCircleOutline, CancelOutlined } from '@mui/icons-material';

function Login({ setIsAuthenticated }) {
  const theme = useTheme();
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiHealth, setApiHealth] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkApiHealth();
  }, []);

  const checkApiHealth = async () => {
    try {
      const response = await fetch('/api/health');
      setApiHealth(response.ok);
    } catch (error) {
      console.error('API health check failed:', error);
      setApiHealth(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const token = await authenticate(clientId, clientSecret);
      localStorage.setItem('token', token);
      localStorage.setItem('clientName', clientId);
      setIsAuthenticated(true);

      try {
        await fetchWithAuth('/appClient');
        navigate('/');
      } catch (apiError) {
        console.error('API test failed:', apiError);
        setError(`Authentication succeeded, but API test failed. Please contact support.`);
        localStorage.removeItem('token');
        localStorage.removeItem('clientName');
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Authentication failed:', error);
      setError('Authentication failed. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleContactUs = () => {
    window.location.href = 'mailto:sales@catenaclearing.io';
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 4,
          backgroundColor: theme.palette.background.paper,
          position: 'relative', // Added for positioning the status indicator
          animation: 'fadeIn 0.5s ease-out', // Add fade-in animation
          '@keyframes fadeIn': {
            from: { opacity: 0, transform: 'translateY(-20px)' },
            to: { opacity: 1, transform: 'translateY(0)' },
          },
        }}
      >
        {/* Updated API Status Indicator */}
        {apiHealth !== null && (
          <Tooltip title={apiHealth ? "System Online" : "System Offline"} arrow placement="top">
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                color: apiHealth ? theme.palette.success.main : theme.palette.error.main,
                transition: 'color 0.3s ease',
              }}
            >
              {apiHealth ? (
                <CheckCircleOutline fontSize="small" />
              ) : (
                <CancelOutlined fontSize="small" />
              )}
            </Box>
          </Tooltip>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <LockOutlined sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
          <Typography component="h1" variant="h5" sx={{ mb: 3, color: 'text.primary' }}>
            Login to Catena Platform
          </Typography>
          {error && <Alert severity="error" sx={{ width: '100%', mb: 2 }}>{error}</Alert>}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="clientId"
              label="Client ID"
              name="clientId"
              autoFocus
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              disabled={loading}
              sx={{
                '& .MuiInputBase-input': { color: 'text.primary' },
                '& .MuiInputLabel-root': { color: 'text.secondary' },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="clientSecret"
              label="Client Secret"
              type={showPassword ? "text" : "password"}
              id="clientSecret"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              disabled={loading}
              sx={{
                '& .MuiInputBase-input': { color: 'text.primary' },
                '& .MuiInputLabel-root': { color: 'text.secondary' },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      sx={{ color: 'text.primary' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, py: 1.5 }}
              disabled={loading || !apiHealth}
            >
              {loading ? <CircularProgress size={24} /> : 'Login'}
            </Button>

            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="body2" sx={{ mr: 2, color: 'text.secondary' }}>
                Don't have an account?
              </Typography>
              <Button
                variant="outlined"
                size="small"
                startIcon={<Email />}
                onClick={handleContactUs}
                sx={{
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  },
                  transition: 'all 0.3s ease-in-out',
                  textTransform: 'none',
                  px: 2,
                }}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
