import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  Grid
} from '@mui/material';
import { fetchWithAuth } from '../utils/api';
import { PROCESSING_TYPES } from '../constants/processingTypes';
import { EVENT_STATUS_TYPES } from '../constants/eventStatusTypes';
import { SOURCE_TYPES } from '../constants/sourceTypes';

function Retry() {
  const [filters, setFilters] = useState({
    customerId: '',
    tradePartnerId: '',
    processingType: '',
    status: '',
    startDate: '',
    endDate: '',
    source: '',
    pageSize: 20,
    createdAfter: '',
    createdBefore: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [tradePartners, setTradePartners] = useState([]);

  useEffect(() => {
    fetchCustomers();
    fetchTradePartners();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const fetchProcessingIds = async () => {
    const queryParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          value.forEach(v => queryParams.append(key, v));
        } else {
          queryParams.append(key, value);
        }
      }
    });

    try {
      console.log('Fetching processing events with query:', queryParams.toString());
      const response = await fetchWithAuth(`/processingEvent?${queryParams.toString()}`);

      console.log('Processing events response:', response);
      if (Array.isArray(response)) {
        const ids = response.map((event) => event.id);
        console.log('Extracted processing event IDs:', ids);
        return ids;
      }
      console.log('No processing events found');
      return [];
    } catch (err) {
      console.error('Error fetching processing events:', err);
      setError('Failed to fetch processing events. Please try again.');
      return [];
    }
  };

  const fetchCustomers = async () => {
    try {
      const data = await fetchWithAuth('/customer');
      setCustomers(data);
    } catch (err) {
      console.error('Error fetching customers:', err);
    }
  };

  const fetchTradePartners = async () => {
    try {
      const data = await fetchWithAuth('/tradePartner');
      setTradePartners(data);
    } catch (err) {
      console.error('Error fetching trade partners:', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const ids = await fetchProcessingIds();

      if (ids.length === 0) {
        setError('No matching processing events found to retry.');
        return;
      }

      const confirmRetry = window.confirm(`Are you sure you want to retry ${ids.length} processing events?`);

      if (!confirmRetry) {
        setLoading(false);
        return;
      }

      console.log('Making bulk retry request with IDs:', ids);
      const url = `/execution/bulk${filters.source ? `?source=${filters.source}` : ''}`;
      console.log('Request URL:', url);
      console.log('Request body:', JSON.stringify(ids));

      const retryPromises = [
        fetchWithAuth(url, {
          method: 'POST',
          body: JSON.stringify(ids)
        })
      ];

      await Promise.all(retryPromises);
      setSuccess(`Successfully initiated retry for ${ids.length} processing events`);
    } catch (err) {
      setError('Failed to initiate retry requests. Please try again.');
      console.error('Retry error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Retry Processing Events
      </Typography>

      <Paper sx={{ p: 3, mt: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="customer-id-label">Customer ID</InputLabel>
                <Select
                  labelId="customer-id-label"
                  name="customerId"
                  value={filters.customerId}
                  onChange={handleFilterChange}
                  label="Customer ID"
                >
                  {customers && customers.length > 0 ? (
                    customers.map((customer) => (
                      <MenuItem key={customer.id} value={customer.id}>
                        {`${customer.id} - ${customer.name}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No customers available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="trade-partner-id-label">Trade Partner ID</InputLabel>
                <Select
                  labelId="trade-partner-id-label"
                  name="tradePartnerId"
                  value={filters.tradePartnerId}
                  onChange={handleFilterChange}
                  label="Trade Partner ID"
                >
                  {tradePartners && tradePartners.length > 0 ? (
                    tradePartners.map((tradePartner) => (
                      <MenuItem key={tradePartner.id} value={tradePartner.id}>
                        {`${tradePartner.id} - ${tradePartner.name}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No trade partners available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Processing Type</InputLabel>
                <Select>
                  <MenuItem value="">All</MenuItem>
                  {PROCESSING_TYPES.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={filters.status || ["FAILURE"]}
                  onChange={handleFilterChange}
                  label="Status"
                  multiple
                >
                  {EVENT_STATUS_TYPES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                {label}
                </MenuItem>
            ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  type="datetime-local"
                  name="createdAfter"
                  label="Created After"
                  value={filters.createdAfter || new Date(Date.now() - 86400000).toISOString().slice(0, 16)}
                  onChange={handleFilterChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  type="datetime-local"
                  name="createdBefore"
                  label="Created Before"
                  value={filters.createdBefore || new Date().toISOString().slice(0, 16)}
                  onChange={handleFilterChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Source</InputLabel>
                <Select
                  name="source"
                  value={filters.source || ""}
                  onChange={handleFilterChange}
                  label="Source"
                >
                  {SOURCE_TYPES.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="pageSize"
                  type="number"
                  label="Limit"
                  value={filters.pageSize || 20}
                  onChange={handleFilterChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{ mt: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : 'Retry Matching Events'}
              </Button>
            </Grid>
          </Grid>
        </form>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}
      </Paper>
    </Box>
  );
}

export default Retry;
