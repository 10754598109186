/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, useTheme,
  Tooltip, CircularProgress, Alert, Snackbar, Paper, IconButton, Chip,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { fetchWithAuth } from '../utils/api';
import { Visibility as VisibilityIcon } from '@mui/icons-material';

function Executions() {
  const [executions, setExecutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [timeFilter, setTimeFilter] = useState('week');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();

  const getTimeRange = (filter) => {
    const now = new Date();
    const endTime = now.toISOString().split('.')[0] + 'Z';
    let startTime;

    switch (filter) {
      case 'year':
        startTime = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      case 'month':
        startTime = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case 'week':
        startTime = new Date(now.setDate(now.getDate() - 7));
        break;
      case 'day':
        startTime = new Date(now.setDate(now.getDate() - 1));
        break;
      case 'hour':
        startTime = new Date(now.setHours(now.getHours() - 1));
        break;
      default:
        startTime = new Date(now.setDate(now.getDate() - 7));
    }

    return {
      startTime: startTime.toISOString().split('.')[0] + 'Z',
      endTime
    };
  };

  const fetchExecutions = async () => {
    try {
      setLoading(true);
      const { startTime, endTime } = getTimeRange(timeFilter);

      const params = new URLSearchParams({
        startTime,
        endTime,
        page: page.toString(),
        page_size: pageSize.toString()
      });

      const response = await fetchWithAuth(`/execution?${params}`);

      if (Array.isArray(response)) {
        const uniqueExecutions = response.reduce((acc, execution) => {
          if (!acc.some(e => e.execution_id === execution.execution_id)) {
            acc.push({
              ...execution,
              id: execution.execution_id
            });
          }
          return acc;
        }, []);

        setExecutions(uniqueExecutions);
        setTotalRows(uniqueExecutions.length);
        setError(null);
      } else {
        setExecutions([]);
        setTotalRows(0);
        setError('Received unexpected data format from server');
      }
    } catch (error) {
      setExecutions([]);
      setTotalRows(0);
      setError('Failed to fetch executions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExecutions();
  }, [timeFilter, page, pageSize]);

  const handleViewExecution = (id) => {
    navigate(`/executions/${id}`);
  };

  const handleTimeFilterChange = (event) => {
    setTimeFilter(event.target.value);
    setPage(1); // Reset to first page when changing time filter
  };

  const handlePageChange = (newPage) => {
    setPage(newPage + 1); // DataGrid uses 0-based indexing
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset to first page when changing page size
  };

  const CellWithTooltip = ({ value }) => (
    <Tooltip title={value} arrow>
      <Box sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        {value}
      </Box>
    </Tooltip>
  );

  const StatusLabel = ({ status }) => {
    const isSuccess = status.toLowerCase() === 'success';
    return (
      <Chip
        label={isSuccess ? 'Success' : 'Failed'}
        color={isSuccess ? 'success' : 'error'}
        variant="outlined"
        size="small"
        sx={{
          fontSize: '0.75rem',
          fontWeight: 500,
          '& .MuiChip-label': {
            padding: '0 6px',
          }
        }}
      />
    );
  };

  const columns = [
    { field: 'execution_id', headerName: 'ID', flex: 0.5, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    {
      field: 'execution_start_time',
      headerName: 'Start Time',
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
      renderCell: (params) => <CellWithTooltip value={new Date(params.value).toLocaleString()} />
    },
    {
      field: 'execution_status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: (params) => <StatusLabel status={params.value} />
    },
    { field: 'processing_event_id', headerName: 'Processing Event ID', flex: 1, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'customer_id', headerName: 'Customer ID', flex: 1, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'processing_type', headerName: 'Processing Type', flex: 1, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title="View Execution">
          <IconButton
            onClick={() => handleViewExecution(params.row.execution_id)}
            size="small"
            sx={{ cursor: 'pointer', color: theme.palette.text.secondary }}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
          Executions
        </Typography>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Time Range</InputLabel>
          <Select
            value={timeFilter}
            onChange={handleTimeFilterChange}
            label="Time Range"
          >
            <MenuItem value="year">Past Year</MenuItem>
            <MenuItem value="month">Past Month</MenuItem>
            <MenuItem value="week">Past Week</MenuItem>
            <MenuItem value="day">Past Day</MenuItem>
            <MenuItem value="hour">Past Hour</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Paper
        elevation={3}
        sx={{
          bgcolor: theme.palette.background.paper,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <DataGrid
          rows={executions}
          columns={columns}
          autoHeight
          disableColumnResize
          disableRowSelectionOnClick
          density="compact"
          loading={loading}
          paginationMode="server"
          rowCount={totalRows}
          page={page - 1}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageSizeOptions={[25, 50, 100]}
          initialState={{
            pagination: {
              pageSize: 100,
            },
          }}
          sx={{
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            border: 'none',
          }}
        />
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Executions;
