import React from 'react';
import { Box, Typography, Stack, LinearProgress, ToggleButtonGroup, ToggleButton, Card, CardContent } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTheme } from '@mui/material/styles';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const StyledText = styled('text')(({ theme, variant }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: variant === 'h4' ? 20 : 12,
  fontWeight: variant === 'h4' ? 'bold' : 'normal',
}));

function PieCenterLabel({ total }) {
  const { width, height, left, top } = useDrawingArea();

  return (
    <React.Fragment>
      <StyledText x={left + width / 2} y={top + height / 2 - 8} variant="h4">
        {total}
      </StyledText>
      <StyledText x={left + width / 2} y={top + height / 2 + 12}>
        Total
      </StyledText>
    </React.Fragment>
  );
}

const SuccessRateChart = ({ filteredSuccessRate, totalSuccessRate, timeFilter }) => {
  const theme = useTheme();
  const [displayMode, setDisplayMode] = React.useState('filtered');

  const successRate = displayMode === 'filtered' ? filteredSuccessRate : totalSuccessRate;
  const total = successRate.success + successRate.failure;
  const successPercentage = total > 0 ? Math.round((successRate.success / total) * 100) : 0;
  const failurePercentage = total > 0 ? Math.round((successRate.failure / total) * 100) : 0;

  const data = [
    { id: 0, value: successRate.success, label: 'Success' },
    { id: 1, value: successRate.failure, label: 'Failure' },
  ];

  const colors = [theme.palette.success.main, theme.palette.error.main];

  const legendItems = [
    { label: 'Success', value: successPercentage, color: theme.palette.success.main, icon: <CheckCircleOutlineIcon /> },
    { label: 'Failure', value: failurePercentage, color: theme.palette.error.main, icon: <ErrorOutlineIcon /> },
  ];

  const handleDisplayModeChange = (event, newMode) => {
    if (newMode !== null) {
      setDisplayMode(newMode);
    }
  };

  return (
    <Card
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        bgcolor: theme.palette.background.paper,
      }}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
          <Typography variant="subtitle2" component="h2">
            Execution Rate
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={displayMode}
            exclusive
            onChange={handleDisplayModeChange}
          >
            <ToggleButton value="filtered" sx={{ py: 0.25, px: 0.75 }}>
              {timeFilter.charAt(0).toUpperCase() + timeFilter.slice(1)}
            </ToggleButton>
            <ToggleButton value="total" sx={{ py: 0.25, px: 0.75 }}>
              All Time
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          minHeight: 140,
        }}>
          <PieChart
            series={[
              {
                data,
                innerRadius: 60,
                outerRadius: 80,
                paddingAngle: 0,
                cornerRadius: 0,
                startAngle: -90,
                endAngle: 270,
              },
            ]}
            slotProps={{
              legend: { hidden: true },
            }}
            width={160}
            height={160}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            colors={colors}
          >
            <PieCenterLabel total={total} />
          </PieChart>
        </Box>
        <Box>
          {legendItems.map((item, index) => (
            <Stack
              key={index}
              direction="row"
              sx={{ alignItems: 'center', gap: 1, mb: index === 0 ? 0.5 : 0 }}
            >
              {React.cloneElement(item.icon, {
                sx: {
                  color: item.color,
                  fontSize: 18,
                }
              })}
              <Box sx={{ flexGrow: 1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.8rem' }}>
                    {item.label}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.8rem' }}>
                    {item.value}%
                  </Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  value={item.value}
                  sx={{
                    mt: 0.25,
                    height: 4,
                    borderRadius: 2,
                    backgroundColor: theme.palette.grey[200],
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 2,
                      backgroundColor: item.color,
                    },
                  }}
                />
              </Box>
            </Stack>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SuccessRateChart;
