export const PROCESSING_TYPES = [
    { value: 'banyanShipment', label: 'Banyan Shipment' },
    { value: 'catenaTest', label: 'Catena Test' },
    { value: 'foodPartnersShipment', label: 'Food Partners Shipment' },
    { value: 'formProductDetection', label: 'Form Product Detection' },
    { value: 'overhaulTracking', label: 'Overhaul Tracking' },
    { value: 'taiShipment', label: 'TAI Shipment' },
    { value: 'uberFreightShipment', label: 'Uber Freight Shipment' },
    { value: 'vizionContainerUpdate', label: 'Vizion Container Update' },
    { value: 'roseRocketShipment', label: 'Rose Rocket Shipment' },
    { value: 'sapShipment', label: 'SAP Shipment' },
    { value: 'mercuryGateShipment', label: 'MercuryGate Shipment' },
    { value: 'revenovaShipment', label: 'Revenova Shipment' }
  ];
