import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/api';
import { Link } from 'react-router-dom';
import {
  Box, Typography, Button, Modal, Select, MenuItem, FormControl, InputLabel,
  IconButton, Tooltip, CircularProgress, Alert, Snackbar, useTheme
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import { PROCESSING_TYPES } from '../constants/processingTypes';
import JSONEditor from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import Chip from '@mui/material/Chip';

function ProcessingConfigsManagement() {
  const theme = useTheme();
  const [processingConfigs, setProcessingConfigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(null);
  const [formData, setFormData] = useState({
    processing_type: '',
    customer_id: '',
    trade_partner_id: '',
    config: { pipelines: [] },
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [customers, setCustomers] = useState([]);
  const [tradePartners, setTradePartners] = useState([]);

  useEffect(() => {
    fetchProcessingConfigs();
    fetchCustomers();
    fetchTradePartners();
  }, []);

  const fetchProcessingConfigs = async () => {
    try {
      setLoading(true);
      const data = await fetchWithAuth('/processingConfig');
      setProcessingConfigs(data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching processing configs:', err);
      setError(`Failed to fetch processing configs: ${err.message}`);
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      const data = await fetchWithAuth('/customer');
      setCustomers(data);
    } catch (err) {
      console.error('Error fetching customers:', err);
    }
  };

  const fetchTradePartners = async () => {
    try {
      const data = await fetchWithAuth('/tradePartner');
      setTradePartners(data);
    } catch (err) {
      console.error('Error fetching trade partners:', err);
    }
  };

  const handleProcessingConfig = async (isCreate) => {
    try {
      const method = isCreate ? 'POST' : 'PATCH';
      const url = isCreate ? '/processingConfig' : `/processingConfig/${currentConfig.id}`;
      await fetchWithAuth(url, {
        method: method,
        body: JSON.stringify(formData),
      });
      setOpenModal(false);
      fetchProcessingConfigs();
      setSnackbar({ open: true, message: `Processing config ${isCreate ? 'created' : 'updated'} successfully`, severity: 'success' });
    } catch (err) {
      setSnackbar({ open: true, message: `Failed to ${isCreate ? 'create' : 'update'} processing config`, severity: 'error' });
    }
  };

  const deleteProcessingConfig = async (id) => {
    if (window.confirm('Are you sure you want to delete this processing config?')) {
      try {
        await fetchWithAuth(`/processingConfig/${id}`, { method: 'DELETE' });
        fetchProcessingConfigs();
        setSnackbar({ open: true, message: 'Processing config deleted successfully', severity: 'success' });
      } catch (err) {
        setSnackbar({ open: true, message: 'Failed to delete processing config', severity: 'error' });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleJsonChange = (json) => {
    if (!json.error) {
      setFormData({ ...formData, config: json.jsObject });
    }
  };

  const handleEditClick = (params) => {
    setCurrentConfig(params.row);
    setFormData({
      processing_type: params.row.processing_type,
      customer_id: params.row.customer_id,
      trade_partner_id: params.row.trade_partner_id,
      config: params.row.config || { pipelines: [] },  // Ensure config and pipelines exist
    });
    setOpenModal(true);
  };

  const renderModal = () => {
    const isCreate = !currentConfig;
    const title = isCreate ? 'Create New Processing Config' : 'Update Processing Config';

    return (
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1200,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: 2,
        }}>
          <Typography variant="h6" component="h2" gutterBottom>{title}</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="processing-type-label">Processing Type</InputLabel>
            <Select>
              <MenuItem value="">All</MenuItem>
              {PROCESSING_TYPES.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="customer-id-label">Customer ID</InputLabel>
            <Select
              labelId="customer-id-label"
              name="customer_id"
              value={formData.customer_id}
              onChange={handleInputChange}
              label="Customer ID"
            >
              {customers && customers.length > 0 ? (
                customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>{`${customer.id} - ${customer.name}`}</MenuItem>
                ))
              ) : (
                <MenuItem disabled>No customers available</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="trade-partner-id-label">Trade Partner ID</InputLabel>
            <Select
              labelId="trade-partner-id-label"
              name="trade_partner_id"
              value={formData.trade_partner_id}
              onChange={handleInputChange}
              label="Trade Partner ID"
            >
              {tradePartners && tradePartners.length > 0 ? (
                tradePartners.map((tradePartner) => (
                  <MenuItem key={tradePartner.id} value={tradePartner.id}>{`${tradePartner.id} - ${tradePartner.name}`}</MenuItem>
                ))
              ) : (
                <MenuItem disabled>No trade partners available</MenuItem>
              )}
            </Select>
          </FormControl>
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Config JSON:</Typography>
          <JSONEditor
            placeholder={formData.config || {}}
            locale={locale}
            height="250px"
            width="100%"
            onChange={handleJsonChange}
            viewOnly={false}
            confirmGood={false}
          />
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Pipelines:</Typography>
          {formData.config && formData.config.pipelines && formData.config.pipelines.length > 0 ? (
            formData.config.pipelines.map((pipeline, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                {pipeline.steps && pipeline.steps.length > 0 && pipeline.steps.map((step, stepIndex) => (
                  <Box key={stepIndex} sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>Pipeline {index + 1}, {step.id || `Step ${stepIndex + 1}`}:</Typography>
                    <JSONEditor
                      placeholder={step}
                      locale={locale}
                      height="150px"
                      width="100%"
                      onChange={(json) => {
                        if (!json.error) {
                          const newPipelines = [...formData.config.pipelines];
                          if (!newPipelines[index]) newPipelines[index] = { steps: [] };
                          newPipelines[index].steps[stepIndex] = json.jsObject;
                          setFormData(prevState => ({
                            ...prevState,
                            config: {
                              ...prevState.config,
                              pipelines: newPipelines
                            }
                          }));
                        }
                      }}
                      viewOnly={false}
                      confirmGood={false}
                    />
                  </Box>
                ))}
                {pipeline.delivery && pipeline.delivery.length > 0 && pipeline.delivery.map((delivery, deliveryIndex) => (
                  <Box key={deliveryIndex} sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>Pipeline {index + 1}, {delivery.id || `Delivery ${deliveryIndex + 1}`}:</Typography>
                    <JSONEditor
                      placeholder={delivery}
                      locale={locale}
                      height="150px"
                      width="100%"
                      onChange={(json) => {
                        if (!json.error) {
                          const newPipelines = [...formData.config.pipelines];
                          if (!newPipelines[index]) newPipelines[index] = { delivery: [] };
                          newPipelines[index].delivery[deliveryIndex] = json.jsObject;
                          setFormData(prevState => ({
                            ...prevState,
                            config: {
                              ...prevState.config,
                              pipelines: newPipelines
                            }
                          }));
                        }
                      }}
                      viewOnly={false}
                      confirmGood={false}
                    />
                  </Box>
                ))}
              </Box>
            ))
          ) : (
            <Typography variant="body1" color="text.secondary">No pipelines configured.</Typography>
          )}
          <Button onClick={() => handleProcessingConfig(isCreate)} variant="contained" sx={{ mt: 2 }}>
            {isCreate ? 'Create' : 'Update'}
          </Button>
        </Box>
      </Modal>
    );
  };

  const CellWithTooltip = ({ value }) => (
    <Tooltip title={value} arrow>
      <Box sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        {value}
      </Box>
    </Tooltip>
  );

  const columns = [
    {
      field: 'processing_type',
      headerName: 'Processing Type',
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color="primary"
          variant="outlined"
          size="small"
          sx={{ fontSize: '0.75rem' }}
        />
      )
    },
    {
      field: 'customer_id',
      headerName: 'Customer Name',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={customers.find(c => c.id === params.value)?.name || params.value} />
    },
    {
      field: 'trade_partner_id',
      headerName: 'Trade Partner Name',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={tradePartners.find(tp => tp.id === params.value)?.name || params.value} />
    },
    {
      field: 'version',
      headerName: 'Version',
      flex: 0.5,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params)}
              size="small"
              sx={{ color: theme.palette.text.primary }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => deleteProcessingConfig(params.id)}
              size="small"
              sx={{ color: theme.palette.error.main }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Configuration">
            <IconButton
              component={Link}
              to={`/config-editor/${params.id}`}
              size="small"
              onClick={(e) => e.stopPropagation()}
              sx={{ color: theme.palette.text.primary }}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  if (loading) return <Box display="flex" justifyContent="center" m={4}><CircularProgress /></Box>;

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" color="text.primary">Processing Configs Management</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentConfig(null);
            setFormData({
              processing_type: '',
              customer_id: '',
              trade_partner_id: '',
              config: { pipelines: [] },
            });
            setOpenModal(true);
          }}
        >
          Create New Processing Config
        </Button>
      </Box>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Paper
        elevation={3}
        sx={{
          bgcolor: theme.palette.background.paper,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <DataGrid
          rows={processingConfigs}
          columns={columns}
          autoHeight
          disableColumnResize
          disableRowSelectionOnClick
          density="compact"
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            border: 'none',
          }}
        />
      </Paper>
      {renderModal()}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ProcessingConfigsManagement;
