import React from 'react';
import { Popover, Typography, Box } from '@mui/material';

const NotificationsPopover = ({ id, open, anchorEl, handleClose }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{ p: 2, width: 300 }}>
        <Typography variant="h6" gutterBottom>Notifications</Typography>
      </Box>
    </Popover>
  );
};

export default NotificationsPopover;
