import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Typography, Button, TextField, Select, MenuItem,
  FormControl, InputLabel, Alert, CircularProgress, Stepper, Step, StepLabel,
  Paper, Grid, Divider, Link
} from '@mui/material';
import { Rocket as LaunchIcon } from '@mui/icons-material';
import { fetchWithAuth } from '../utils/api';

const Launch = () => {
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [urlParams, setUrlParams] = useState({});
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const fetchApiDocs = async () => {
      try {
        const response = await fetch('/api_docs.json');
        const apiDocs = await response.json();

        const processingRoutes = Object.entries(apiDocs.paths)
          .filter(([_, pathObj]) => pathObj.post && pathObj.post.tags.includes('processing'))
          .map(([path, pathObj]) => ({
            path,
            summary: pathObj.post.summary,
            description: pathObj.post.description,
            operationId: pathObj.post.operationId,
            requestBody: pathObj.post.requestBody,
            responses: pathObj.post.responses,
            parameters: pathObj.post.parameters,
          }));
        setRoutes(processingRoutes.sort((a, b) => formatRouteName(a.operationId).localeCompare(formatRouteName(b.operationId))));
      } catch (err) {
        setError('Failed to load API documentation');
      } finally {
        setLoading(false);
      }
    };

    fetchApiDocs();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setResponse(null);
    setActiveStep(2);

    try {
      let finalPath = selectedRoute;
      // Replace URL parameters with actual values
      Object.entries(urlParams).forEach(([key, value]) => {
        finalPath = finalPath.replace(`{${key}}`, value);
      });

      const response = await fetchWithAuth(finalPath, {
        method: 'POST',
        body: requestBody,
      });

      setResponse(response);
      setActiveStep(3);
    } catch (err) {
      setError(err.message);
      setActiveStep(2);
    }
  };

  const steps = ['Select Route', 'Configure Job', 'Launch', 'Result'];

  const selectedRouteInfo = routes.find(route => route.path === selectedRoute);

  const handleRouteChange = (e) => {
    const newRoute = e.target.value;
    setSelectedRoute(newRoute);
    setActiveStep(1);

    // Reset URL parameters when route changes
    const routeInfo = routes.find(route => route.path === newRoute);
    if (routeInfo && routeInfo.parameters) {
      const newUrlParams = {};
      routeInfo.parameters.forEach(param => {
        if (param.in === 'path') {
          newUrlParams[param.name] = '';
        }
      });
      setUrlParams(newUrlParams);
    } else {
      setUrlParams({});
    }
  };

  const formatRouteName = (operationId) => {
    // Remove the _post suffix
    let name = operationId.replace(/_post$/, '');
    // Split by underscore and capitalize each word
    name = name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return name;
  };

  const renderPlaceholder = () => (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: 'text.secondary',
      p: 3
    }}>
      <Typography variant="h5" gutterBottom>
        Route Information
      </Typography>
      <Typography variant="body1">
        Select a processing route from the dropdown menu on the left to view its details here.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        You'll see information about:
      </Typography>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li>• Route description</li>
        <li>• Request details</li>
        <li>• Response information</li>
      </ul>
    </Box>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" component="div" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          Launch Processing Job
        </Typography>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="route-select-label">Select Processing Route</InputLabel>
                <Select
                  labelId="route-select-label"
                  value={selectedRoute}
                  onChange={handleRouteChange}
                  required
                  label="Select Processing Route"
                >
                  <MenuItem value="">
                    <em>Select a route...</em>
                  </MenuItem>
                  {routes.map((route) => (
                    <MenuItem key={route.path} value={route.path}>
                      {formatRouteName(route.summary)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedRouteInfo && selectedRouteInfo.parameters && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>URL Parameters</Typography>
                  {selectedRouteInfo.parameters.map(param => (
                    param.in === 'path' && (
                      <TextField
                        key={param.name}
                        fullWidth
                        margin="normal"
                        label={param.name}
                        value={urlParams[param.name] || ''}
                        onChange={(e) => setUrlParams({...urlParams, [param.name]: e.target.value})}
                        required
                      />
                    )
                  ))}
                </Box>
              )}

              <TextField
                fullWidth
                margin="normal"
                label="Request Body (JSON)"
                multiline
                rows={8}
                value={requestBody}
                onChange={(e) => setRequestBody(e.target.value)}
                required
                sx={{ fontFamily: 'monospace' }}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 2 }}
                startIcon={<LaunchIcon />}
                disabled={!selectedRoute || !requestBody || Object.values(urlParams).some(param => param === '')}
              >
                Launch Job
              </Button>
            </form>

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Error: {error}
              </Alert>
            )}

            {response && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Job launched successfully! Processing ID: {response.id}
                <Box sx={{ mt: 1 }}>
                  <Link component={RouterLink} to="/executions">
                    View Executions
                  </Link>
                </Box>
              </Alert>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{
              pl: { md: 4 },
              borderLeft: { md: 1 },
              borderColor: 'divider',
              height: '100%',
              minHeight: '400px' // Set a minimum height to ensure consistent layout
            }}>
              {selectedRouteInfo ? (
                <Box>
                  <Typography variant="h5" gutterBottom>
                    {formatRouteName(selectedRouteInfo.summary)}
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ whiteSpace: 'pre-line' }}>
                    {selectedRouteInfo.description}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" gutterBottom>
                    Request Details
                  </Typography>
                  {selectedRouteInfo.requestBody && (
                    <Typography variant="body2" paragraph>
                      <strong>Request Body:</strong> {selectedRouteInfo.requestBody.content['application/json'].schema.title}
                    </Typography>
                  )}
                  <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                    Response Details
                  </Typography>
                  {selectedRouteInfo.responses && (
                    <Box>
                      {Object.entries(selectedRouteInfo.responses).map(([code, response]) => (
                        <Typography key={code} variant="body2" paragraph>
                          <strong>{code}:</strong> {response.description}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              ) : (
                renderPlaceholder()
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Launch;
