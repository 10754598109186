import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/api';
import {
  Box, Typography, Button, Modal, TextField, IconButton, Tooltip, CircularProgress, Alert, Snackbar, useTheme
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';

function CustomersManagement() {
  const theme = useTheme();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [formData, setFormData] = useState({ name: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const data = await fetchWithAuth('/customer');
      setCustomers(data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching customers:', err);
      setError(`Failed to fetch customers: ${err.message}`);
      setLoading(false);
    }
  };

  const handleCustomer = async (isCreate) => {
    try {
      const method = isCreate ? 'POST' : 'PATCH';
      const url = isCreate ? '/customer' : `/customer/${currentCustomer.id}`;
      await fetchWithAuth(url, {
        method: method,
        body: JSON.stringify(formData),
      });
      setOpenModal(false);
      fetchCustomers();
      setSnackbar({ open: true, message: `Customer ${isCreate ? 'created' : 'updated'} successfully`, severity: 'success' });
    } catch (err) {
      setSnackbar({ open: true, message: `Failed to ${isCreate ? 'create' : 'update'} customer`, severity: 'error' });
    }
  };

  const deleteCustomer = async (id) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        await fetchWithAuth(`/customer/${id}`, { method: 'DELETE' });
        fetchCustomers();
        setSnackbar({ open: true, message: 'Customer deleted successfully', severity: 'success' });
      } catch (err) {
        setSnackbar({ open: true, message: 'Failed to delete customer', severity: 'error' });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditClick = (params) => {
    setCurrentCustomer(params.row);
    setFormData({ name: params.row.name });
    setOpenModal(true);
  };

  const renderModal = () => {
    const isCreate = !currentCustomer;
    const title = isCreate ? 'Create New Customer' : 'Update Customer';

    return (
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography variant="h6" component="h2" gutterBottom>{title}</Typography>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Customer Name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <Button onClick={() => handleCustomer(isCreate)} variant="contained" sx={{ mt: 2 }}>
            {isCreate ? 'Create' : 'Update'}
          </Button>
        </Box>
      </Modal>
    );
  };

  const CellWithTooltip = ({ value }) => (
    <Tooltip title={value} arrow>
      <Box sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        {value}
      </Box>
    </Tooltip>
  );

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'slug',
      headerName: 'Slug',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params)}
              size="small"
              sx={{ color: theme.palette.text.primary }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => deleteCustomer(params.id)}
              size="small"
              sx={{ color: theme.palette.error.main }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  if (loading) return <Box display="flex" justifyContent="center" m={4}><CircularProgress /></Box>;

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" color="text.primary">Customers Management</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentCustomer(null);
            setFormData({ name: '' });
            setOpenModal(true);
          }}
        >
          Create New Customer
        </Button>
      </Box>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Paper
        elevation={3}
        sx={{
          bgcolor: theme.palette.background.paper,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <DataGrid
          rows={customers}
          columns={columns}
          autoHeight
          disableColumnResize
          disableRowSelectionOnClick
          density="compact"
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            border: 'none',
          }}
        />
      </Paper>
      {renderModal()}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default CustomersManagement;
