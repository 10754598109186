import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import AppClientsManagement from './AppClientsManagement';
import CustomersManagement from './CustomersManagement';
import TradePartnersManagement from './TradePartnersManagement';
import ProcessingConfigsManagement from './ProcessingConfigsManagement';
import ProcessingConnectionsManagement from './ProcessingConnectionsManagement';
import ConfigVisualization from './ConfigVisualization';
import Executions from './Executions';
import ExecutionDetails from './ExecutionDetails';
import NotFound from './common/NotFound';
import Launch from './Launch';
import ProcessingEvents from './ProcessingEvents';
import Retry from './Retry';


function AppContent({ mode, toggleColorMode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isLoading && isAuthenticated && location.pathname !== '/login') {
      localStorage.setItem('lastRoute', location.pathname);
    }
  }, [location, isAuthenticated, isLoading]);

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    localStorage.removeItem('clientName');
    localStorage.removeItem('lastRoute');
    navigate('/login');
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  return (
    <Routes>
      <Route path="/login" element={
        isAuthenticated ? <Navigate to={localStorage.getItem('lastRoute') || '/'} /> : <Login setIsAuthenticated={setIsAuthenticated} />
      } />
      <Route
        path="/"
        element={isAuthenticated ? (
          <Dashboard mode={mode} toggleColorMode={toggleColorMode} handleLogout={handleLogout} />
        ) : (
          <Navigate to="/login" />
        )}
      >
        <Route index element={<div>Welcome to the Dashboard</div>} />
        <Route path="app-clients" element={<AppClientsManagement />} />
        <Route path="customers" element={<CustomersManagement />} />
        <Route path="trade-partners" element={<TradePartnersManagement />} />
        <Route path="processing-configs" element={<ProcessingConfigsManagement />} />
        <Route path="processing-connections" element={<ProcessingConnectionsManagement />} />
        <Route path="config-editor/:configId" element={<ConfigVisualization />} />
        <Route path="executions" element={<Executions />} />
        <Route path="executions/:id" element={<ExecutionDetails />} />
        <Route path="launch" element={<Launch />} />
        <Route path="processing-events" element={<ProcessingEvents />} />
        <Route path="retry" element={<Retry />} />
      </Route>
      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to={isAuthenticated ? '/not-found' : '/login'} replace />} />
    </Routes>
  );
}

export default AppContent;
