import React from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

const ActivityChart = ({ activityData, timeFilter, setTimeFilter }) => {
  const theme = useTheme();

  // Get unique processing types from the data and sort them alphabetically
  const processingTypes = [...new Set(activityData.flatMap(item => Object.keys(item).filter(key => key !== 'name')))].sort();

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 500,
        bgcolor: theme.palette.background.paper,
        overflow: 'hidden',
        // border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography component="h2" variant="h6" color="text.primary" gutterBottom>
          Execution Activity
        </Typography>
        <FormControl variant="outlined" size="small">
          <InputLabel>Time Range</InputLabel>
          <Select
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
            label="Time Range"
          >
            <MenuItem value="year">Past Year</MenuItem>
            <MenuItem value="month">Past Month</MenuItem>
            <MenuItem value="week">Past Week</MenuItem>
            <MenuItem value="day">Past Day</MenuItem>
            <MenuItem value="hour">Past Hour</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <BarChart
        dataset={activityData}
        xAxis={[{
          scaleType: 'band',
          dataKey: 'name',
        }]}
        series={processingTypes.map(type => ({
          dataKey: type,
          label: type,
          stack: 'total',
        }))}
        height={400}
        layout="vertical"
        slotProps={{
          legend: {
            hidden: true,
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0,
          },
        }}
      />
    </Paper>
  );
};

export default ActivityChart;
