import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../utils/api';
import {
  Box, Typography, Button, Modal, TextField, Select, MenuItem, FormControl, InputLabel,
  Chip, IconButton, Tooltip, CircularProgress, Alert, Snackbar, useTheme
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';

function AppClientsManagement() {
  const theme = useTheme();
  const [appClients, setAppClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentAppClient, setCurrentAppClient] = useState(null);
  const [formData, setFormData] = useState({
    scopes: [],
    trade_partner_id: '',
    customer_id: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchAppClients();
  }, []);

  const fetchAppClients = async () => {
    try {
      setLoading(true);
      const data = await fetchWithAuth('/appClient');
      setAppClients(data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching app clients:', err);
      setError(`Failed to fetch app clients: ${err.message}`);
      setLoading(false);
    }
  };

  const handleAppClient = async (isCreate) => {
    try {
      const method = isCreate ? 'POST' : 'PATCH';
      const url = isCreate ? '/appClient' : `/appClient/${currentAppClient.id}`;
      await fetchWithAuth(url, {
        method: method,
        body: JSON.stringify(formData),
      });
      setOpenModal(false);
      fetchAppClients();
      setSnackbar({ open: true, message: `App client ${isCreate ? 'created' : 'updated'} successfully`, severity: 'success' });
    } catch (err) {
      setSnackbar({ open: true, message: `Failed to ${isCreate ? 'create' : 'update'} app client`, severity: 'error' });
    }
  };

  const deleteAppClient = async (id) => {
    if (window.confirm('Are you sure you want to delete this app client?')) {
      try {
        await fetchWithAuth(`/appClient/${id}`, { method: 'DELETE' });
        fetchAppClients();
        setSnackbar({ open: true, message: 'App client deleted successfully', severity: 'success' });
      } catch (err) {
        setSnackbar({ open: true, message: 'Failed to delete app client', severity: 'error' });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleScopeChange = (event) => {
    setFormData({ ...formData, scopes: event.target.value });
  };

  const handleEditClick = (params) => {
    setCurrentAppClient(params.row);
    setFormData({
      scopes: params.row.scopes,
      trade_partner_id: params.row.trade_partner_id,
      customer_id: params.row.customer_id,
    });
    setOpenModal(true);
  };

  const renderModal = () => {
    const isCreate = !currentAppClient;
    const title = isCreate ? 'Create New App Client' : 'Update App Client';

    return (
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography variant="h6" component="h2" gutterBottom>{title}</Typography>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel id="scopes-label">Scopes</InputLabel>
            <Select
              labelId="scopes-label"
              multiple
              value={formData.scopes}
              onChange={handleScopeChange}
              label="Scopes"
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
            >
              {['write:form.productDetection', 'write:overhaul.tracking', 'write:vizion.containerUpdate', 'write:uberFreight.shipment', 'write:banyan.shipment', 'write:foodPartners.shipment', 'write:tai.shipment', 'write:customer', 'write:tradePartner', 'write:appClient', 'write:catena.test', 'write:processingConfig', 'write:processingConnection', 'read:customer', 'read:tradePartner', 'read:appClient', 'read:processingConfig', 'read:processingConnection'].map((scope) => (
                <MenuItem key={scope} value={scope}>
                  {scope}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            name="trade_partner_id"
            label="Trade Partner ID"
            value={formData.trade_partner_id}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="customer_id"
            label="Customer ID"
            value={formData.customer_id}
            onChange={handleInputChange}
          />
          <Button onClick={() => handleAppClient(isCreate)} variant="contained" sx={{ mt: 2 }}>
            {isCreate ? 'Create' : 'Update'}
          </Button>
        </Box>
      </Modal>
    );
  };

  const CellWithTooltip = ({ value }) => (
    <Tooltip title={value} arrow>
      <Box sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        {value}
      </Box>
    </Tooltip>
  );

  const ScopesCell = ({ scopes }) => {
    const displayLimit = 2;
    const displayedScopes = scopes.slice(0, displayLimit);
    const remainingCount = scopes.length - displayLimit;

    return (
      <Tooltip title={scopes.join(', ')} arrow>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.5,
          alignItems: 'center',
          height: '100%', // Ensure the Box takes full height of the cell
          justifyContent: 'center', // Center content horizontally
        }}>
          {displayedScopes.map((scope) => (
            <Chip key={scope} label={scope} size="small" sx={{ fontSize: '0.75rem' }} />
          ))}
          {remainingCount > 0 && (
            <Typography variant="caption" color="text.secondary">
              +{remainingCount} more
            </Typography>
          )}
        </Box>
      </Tooltip>
    );
  };

  const columns = [
    {
      field: 'app_client_name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'client_id',
      headerName: 'Client ID',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'scopes',
      headerName: 'Scopes',
      flex: 2,
      renderCell: (params) => <ScopesCell scopes={params.value} />
    },
    {
      field: 'trade_partner_id',
      headerName: 'Trade Partner ID',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'customer_id',
      headerName: 'Customer ID',
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEditClick(params)}
              size="small"
              sx={{ color: theme.palette.text.primary }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => deleteAppClient(params.id)}
              size="small"
              sx={{ color: theme.palette.error.main }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  if (loading) return <Box display="flex" justifyContent="center" m={4}><CircularProgress /></Box>;

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" color="text.primary">App Clients Management</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setCurrentAppClient(null);
            setFormData({ scopes: [], trade_partner_id: '', customer_id: '' });
            setOpenModal(true);
          }}
        >
          Create New App Client
        </Button>
      </Box>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Paper
        elevation={3}
        sx={{
          bgcolor: theme.palette.background.paper,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <DataGrid
          rows={appClients}
          columns={columns}
          autoHeight
          disableColumnResize
          disableRowSelectionOnClick
          density="compact"
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              alignItems: 'center', // Vertically center all cell contents
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            border: 'none', // Remove default border
          }}
        />
      </Paper>
      {renderModal()}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AppClientsManagement;
