const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const TOKEN_URL = process.env.REACT_APP_TOKEN_URL || 'https://catenaclearing.auth.us-east-1.amazoncognito.com/oauth2/token';

export async function authenticate(clientId, clientSecret) {
  try {
    const response = await fetch(TOKEN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        client_id: clientId,
        client_secret: clientSecret,
      }),
    });

    if (!response.ok) {
      const errorData = await response.text();
      throw new Error(errorData || 'Authentication failed');
    }

    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error('Authentication error:', error);
    throw error;
  }
}

export async function fetchWithAuth(endpoint, options = {}) {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  const url = `${API_BASE_URL}${endpoint}`;

  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed (${response.status}): ${response.statusText}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json();
    } else {
      throw new Error('Unexpected response type from server');
    }
  } catch (error) {
    throw error;
  }
}
