import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Grid, useMediaQuery, Typography, Paper, Tooltip, Chip, Stack
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fetchWithAuth } from '../utils/api';
import Layout from './dashboard/Layout';
import DashboardSummary from './dashboard/DashboardSummary';
import ActivityChart from './dashboard/ActivityChart';
import SuccessRateChart from './dashboard/SuccessRateChart';
import NotificationsPopover from './common/NotificationsPopover';
import { DataGrid } from '@mui/x-data-grid';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import ExecutionTrend from './dashboard/ExecutionTrend';

function Dashboard({ mode, toggleColorMode, handleLogout }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const [counts, setCounts] = useState({
    appClients: 0,
    customers: 0,
    tradePartners: 0,
    processingConfigs: 0,
    processingConnections: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [timeFilter, setTimeFilter] = useState('week');
  const [activityData, setActivityData] = useState([]);
  const [recentExecutions, setRecentExecutions] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [countsLoading, setCountsLoading] = useState(true);
  const [executionsLoading, setExecutionsLoading] = useState(true);
  const [allExecutions, setAllExecutions] = useState([]);
  const [filteredSuccessRate, setFilteredSuccessRate] = useState({ success: 0, failure: 0 });
  const [totalSuccessRate, setTotalSuccessRate] = useState({ success: 0, failure: 0 });
  const [filteredExecutions, setFilteredExecutions] = useState([]);

  const getTimeRangeInMilliseconds = useCallback((timeFilter) => {
    const HOUR = 60 * 60 * 1000;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const MONTH = 30 * DAY;
    const YEAR = 365 * DAY;

    switch (timeFilter) {
      case 'year': return YEAR;
      case 'month': return MONTH;
      case 'week': return WEEK;
      case 'day': return DAY;
      case 'hour': return HOUR;
      default: return WEEK; // Default to a week
    }
  }, []);

  const formatDateForChart = useCallback((date, timeFilter) => {
    switch (timeFilter) {
      case 'year':
        return date.toLocaleString('default', { month: 'short', year: '2-digit' });
      case 'month':
      case 'week':
        return date.toLocaleString('default', { month: 'short', day: 'numeric' });
      case 'day':
        return date.getHours().toString().padStart(2, '0') + ':00';
      case 'hour':
        return date.getMinutes().toString().padStart(2, '0');
      default:
        return date.toLocaleString('default', { month: 'short', day: 'numeric' });
    }
  }, []);

  const updateFilteredSuccessRate = useCallback(() => {
    const successCount = filteredExecutions.filter(execution => execution.execution_status.toLowerCase() === 'success').length;
    const failureCount = filteredExecutions.length - successCount;
    setFilteredSuccessRate({ success: successCount, failure: failureCount });
  }, [filteredExecutions]);

  const updateTotalSuccessRate = useCallback((executions) => {
    const successCount = executions.filter(execution => execution.execution_status.toLowerCase() === 'success').length;
    const failureCount = executions.length - successCount;
    setTotalSuccessRate({ success: successCount, failure: failureCount });
  }, []);

  const fetchRecentExecutions = useCallback(async () => {
    setExecutionsLoading(true);
    const fetchWithRetry = async (endpoint, retries = 3, delay = 1000) => {
      for (let i = 0; i < retries; i++) {
        try {
          const data = await fetchWithAuth(endpoint);
          return data;
        } catch (error) {
          if (i === retries - 1) throw error;
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
    };

    try {
      const now = new Date();
      const endTime = now.toISOString().split('.')[0] + 'Z';
      const startDate = new Date(now.getTime() - getTimeRangeInMilliseconds(timeFilter));
      const startTime = startDate.toISOString().split('.')[0] + 'Z';

      // Fetch first page to get initial data
      const firstPageParams = new URLSearchParams({
        startTime,
        endTime,
        page: '1',
        page_size: '100'
      });

      const firstPage = await fetchWithRetry(`/execution?${firstPageParams}`);
      let allExecutions = [...firstPage];

      // If we got 100 items, there might be more pages
      if (firstPage.length === 100) {
        let currentPage = 2;
        let hasMore = true;

        while (hasMore) {
          const nextPageParams = new URLSearchParams({
            startTime,
            endTime,
            page: currentPage.toString(),
            page_size: '100'
          });

          const nextPage = await fetchWithRetry(`/execution?${nextPageParams}`);

          if (nextPage.length > 0) {
            allExecutions = [...allExecutions, ...nextPage];
            currentPage++;
          } else {
            hasMore = false;
          }

          // Optional: Add a safety limit to prevent infinite loops
          if (currentPage > 10) { // Limit to 1000 executions total
            break;
          }
        }
      }

      // Process the combined results
      const uniqueExecutions = allExecutions.reduce((acc, execution) => {
        if (!acc.some(e => e.execution_id === execution.execution_id)) {
          acc.push({
            ...execution,
            id: execution.execution_id
          });
        }
        return acc;
      }, []);

      setAllExecutions(uniqueExecutions);
      setRecentExecutions(uniqueExecutions);
      updateTotalSuccessRate(uniqueExecutions);
    } catch (error) {
      setAllExecutions([]);
      setRecentExecutions([]);
    }
    setExecutionsLoading(false);
  }, [timeFilter, getTimeRangeInMilliseconds, updateTotalSuccessRate]);

  const processedActivityData = useMemo(() => {
    const now = new Date();
    const timeRanges = {
      year: 12,
      month: 30,
      week: 7,
      day: 24,
      hour: 60
    };

    const timeRange = getTimeRangeInMilliseconds(timeFilter);
    const startDate = new Date(now.getTime() - timeRange);

    const filteredExecutions = allExecutions.filter(execution => {
      const executionDate = new Date(execution.execution_start_time);
      return executionDate >= startDate && executionDate <= now;
    });

    setFilteredExecutions(filteredExecutions);  // Set filtered executions here

    return Array.from({ length: timeRanges[timeFilter] }, (_, i) => {
      let date;
      switch (timeFilter) {
        case 'year':
          date = new Date(now.getFullYear(), now.getMonth() - 11 + i, 1);
          break;
        case 'month':
          date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29 + i);
          break;
        case 'week':
          date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6 + i);
          break;
        case 'day':
          date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() - 23 + i);
          break;
        case 'hour':
          date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() - 59 + i);
          break;
        default:
          date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6 + i);
      }

      const executionsInRange = filteredExecutions.filter(execution => {
        const executionDate = new Date(execution.execution_start_time);
        return (
          executionDate >= date &&
          executionDate < new Date(date.getTime() + getTimeRangeInMilliseconds(timeFilter) / timeRanges[timeFilter])
        );
      });

      const executionsByType = executionsInRange.reduce((acc, execution) => {
        acc[execution.processing_type] = (acc[execution.processing_type] || 0) + 1;
        return acc;
      }, {});

      return {
        name: formatDateForChart(date, timeFilter),
        ...executionsByType
      };
    });
  }, [allExecutions, timeFilter, getTimeRangeInMilliseconds, formatDateForChart]);

  useEffect(() => {
    fetchCounts();
    fetchRecentExecutions();
  }, [fetchRecentExecutions, timeFilter]);

  useEffect(() => {
    setActivityData(processedActivityData);
  }, [processedActivityData]);

  useEffect(() => {
    updateFilteredSuccessRate();
  }, [updateFilteredSuccessRate, filteredExecutions]);

  const fetchCounts = async () => {
    setCountsLoading(true);
    const fetchWithRetry = async (endpoint, retries = 3, delay = 1000) => {
      for (let i = 0; i < retries; i++) {
        try {
          const data = await fetchWithAuth(endpoint);
          return Array.isArray(data) ? data.length : 0;
        } catch (error) {
          if (i === retries - 1) throw error;
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
    };

    try {
      const [appClients, customers, tradePartners, processingConfigs, processingConnections] = await Promise.all([
        fetchWithRetry('/appClient'),
        fetchWithRetry('/customer'),
        fetchWithRetry('/tradePartner'),
        fetchWithRetry('/processingConfig'),
        fetchWithRetry('/processingConnection'),
      ]);

      setCounts({
        appClients,
        customers,
        tradePartners,
        processingConfigs,
        processingConnections,
      });
    } catch (error) {
      setCounts({
        appClients: 0,
        customers: 0,
        tradePartners: 0,
        processingConfigs: 0,
        processingConnections: 0,
      });
    }
    setCountsLoading(false);
  };

  const handleLogoutClick = () => {
    handleLogout();
    navigate('/login');
  };

  const handleThemeChange = () => {
    toggleColorMode();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const notificationOpen = Boolean(notificationAnchorEl);
  const notificationId = notificationOpen ? 'notification-popover' : undefined;

  const CellWithTooltip = ({ value }) => (
    <Tooltip title={value} arrow>
      <Box sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        {value}
      </Box>
    </Tooltip>
  );

  const StatusLabel = ({ status }) => {
    const isSuccess = status.toLowerCase() === 'success';

    return (
      <Chip
        label={isSuccess ? 'Success' : 'Failed'}
        color={isSuccess ? 'success' : 'error'}
        variant="outlined"
        size="small"
        sx={{
          fontSize: '0.75rem',
          fontWeight: 500,
          '& .MuiChip-label': {
            padding: '0 6px',
          }
        }}
      />
    );
  };

  const executionColumns = [
    { field: 'id', headerName: 'ID', flex: 0.5, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    {
      field: 'execution_start_time',
      headerName: 'Start Time',
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
      renderCell: (params) => <CellWithTooltip value={new Date(params.value).toLocaleString()} />
    },
    {
      field: 'execution_status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: (params) => <StatusLabel status={params.value.toLowerCase()} />
    },
    { field: 'processing_id', headerName: 'Processing ID', flex: 1, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'customer_id', headerName: 'Customer ID', flex: 1, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    { field: 'processing_type', headerName: 'Processing Type', flex: 1, renderCell: (params) => <CellWithTooltip value={params.value} /> },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title="View Execution">
          <VisibilityIcon
            onClick={() => navigate(`/executions/${params.id}`)}
            sx={{ cursor: 'pointer', color: theme.palette.text.secondary }}
          />
        </Tooltip>
      ),
    },
  ];

  const executionTrendData = useMemo(() => {
    return processedActivityData.map(day =>
      Object.values(day).reduce((sum, count) =>
        typeof count === 'number' ? sum + count : sum, 0
      )
    );
  }, [processedActivityData]);

  const renderDashboardContent = () => (
    <Box sx={{ flexGrow: 1 }}>
      <DashboardSummary counts={counts} loading={countsLoading} />
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={8}>
          <ActivityChart
            activityData={activityData}
            timeFilter={timeFilter}
            setTimeFilter={setTimeFilter}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={3} sx={{ height: '100%' }}>
            <Paper elevation={3} sx={{ flex: 1 }}>
              <ExecutionTrend
                executionData={executionTrendData}
                timeFilter={timeFilter}
                totalExecutions={filteredExecutions.length}  // Pass total filtered executions
              />
            </Paper>
            <Paper elevation={3} sx={{ flex: 1 }}>
              <SuccessRateChart
                filteredSuccessRate={filteredSuccessRate}
                totalSuccessRate={totalSuccessRate}
                timeFilter={timeFilter}
              />
            </Paper>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>Recent Executions</Typography>
        <Paper
          elevation={3}
          sx={{
            bgcolor: theme.palette.background.paper,
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <DataGrid
            rows={recentExecutions}
            columns={executionColumns}
            autoHeight
            disableColumnResize
            disableRowSelectionOnClick
            density="compact"
            loading={executionsLoading}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            sx={{
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: theme.palette.background.paper,
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
              '& .MuiDataGrid-cell': {
                borderBottom: `1px solid ${theme.palette.divider}`,
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: theme.palette.background.paper,
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              border: 'none',
            }}
          />
        </Paper>
      </Box>
    </Box>
  );

  return (
    <Layout
      mode={mode}
      handleThemeChange={handleThemeChange}
      handleNotificationClick={handleNotificationClick}
      handleMenu={handleMenu}
      handleClose={handleClose}
      handleLogout={handleLogoutClick}
      anchorEl={anchorEl}
      isMobile={isMobile}
      notificationAnchorEl={notificationAnchorEl}
      handleNotificationClose={handleNotificationClose}
    >
      {location.pathname === '/' ? renderDashboardContent() : <Outlet />}
      <NotificationsPopover
        id={notificationId}
        open={notificationOpen}
        anchorEl={notificationAnchorEl}
        handleClose={handleNotificationClose}
      />
    </Layout>
  );
}

export default Dashboard;
