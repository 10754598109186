import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box, List, ListItem, ListItemIcon, ListItemText, Toolbar,
  Typography, Divider, IconButton, Drawer
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  DataObject as ProcessingEventsIcon,
  Assessment as ProcessesIcon,
  Apps as AppClientsIcon,
  People as CustomersIcon,
  Business as TradePartnersIcon,
  Settings as ProcessingConfigsIcon,
  Storage as ProcessingConnectionsIcon,
  Notifications as NotificationsIcon,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
  Rocket as LaunchIcon,
  Description as ApiDocsIcon,
  Replay as RetryIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import UserMenu from '../common/UserMenu';

const menuItems = [
  { section: 'Overview', items: [{ text: 'Dashboard', icon: <DashboardIcon />, path: '/' }] },
  { section: 'Operations', items: [
    { text: 'Processing Events', icon: <ProcessingEventsIcon />, path: '/processing-events' },
    { text: 'Executions', icon: <ProcessesIcon />, path: '/executions' },
    { text: 'Launch', icon: <LaunchIcon />, path: '/launch' },
    { text: 'Retry', icon: <RetryIcon />, path: '/retry' },
  ]},
  { section: 'Management', items: [
    { text: 'App Clients', icon: <AppClientsIcon />, path: '/app-clients' },
    { text: 'Customers', icon: <CustomersIcon />, path: '/customers' },
    { text: 'Trade Partners', icon: <TradePartnersIcon />, path: '/trade-partners' },
  ]},
  { section: 'Configuration', items: [
    { text: 'Configs', icon: <ProcessingConfigsIcon />, path: '/processing-configs' },
    { text: 'Connections', icon: <ProcessingConnectionsIcon />, path: '/processing-connections' },
  ]},
];

const helpItems = [
  { text: 'API Docs', icon: <ApiDocsIcon />, path: 'https://api.catenaclearing.io/redoc', external: true },
];

const SIDEBAR_WIDTH = 200;

const Sidebar = ({
  isMobile,
  handleDrawerToggle,
  darkMode,
  handleThemeChange,
  handleNotificationClick,
  handleMenu,
  handleClose,
  handleLogout,
  anchorEl
}) => {
  const theme = useTheme();
  const location = useLocation();

  const drawerContent = (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      bgcolor: theme.palette.background.paper,
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': { display: 'none' },
      scrollbarWidth: 'none',
    }}>
      <Toolbar sx={{ minHeight: 48, py: 0.5, display: 'flex', justifyContent: 'center' }}>
        <Link to="/">
          <img
            src={theme.palette.mode === 'dark' ? "/catena-logo-landscape-dark.png" : "/catena-logo-landscape.png"}
            alt="Catena Clearing"
            style={{
              height: '32px',
              width: 'auto',
              maxWidth: '100%',
            }}
          />
        </Link>
      </Toolbar>
      <Divider />
      <List sx={{ flexGrow: 1, py: 1 }}>
        {menuItems.map((section, index) => (
          <React.Fragment key={section.section}>
            {index > 0 && <Box sx={{ height: 16 }} />}
            <Typography variant="caption" sx={{
              color: theme.palette.text.secondary,
              fontWeight: 'bold',
              pl: 1.5,
              pt: 1,
              pb: 0.5,
              display: 'block',
            }}>
              {section.section}
            </Typography>
            {section.items.map((item) => (
              <ListItem
                key={item.text}
                component={item.external ? 'a' : Link}
                to={!item.external ? item.path : undefined}
                href={item.external ? item.path : undefined}
                target={item.external ? "_blank" : undefined}
                rel={item.external ? "noopener noreferrer" : undefined}
                selected={!item.external && location.pathname === item.path}
                onClick={isMobile ? handleDrawerToggle : undefined}
                sx={{
                  py: 0.5,
                  minHeight: 36,
                  px: 1.5,
                  color: theme.palette.text.primary,
                  textDecoration: 'none',
                  '&.Mui-selected': {
                    bgcolor: theme.palette.action.selected,
                    '&:hover': { bgcolor: theme.palette.action.hover },
                  },
                  '&:hover': { bgcolor: theme.palette.action.hover },
                  '&:visited': {
                    color: theme.palette.text.primary,
                  },
                }}
              >
                <ListItemIcon sx={{
                  color: location.pathname === item.path ? theme.palette.primary.main : theme.palette.text.primary,
                  minWidth: 32,
                }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontSize: '0.875rem',
                    fontWeight: location.pathname === item.path ? 'medium' : 'normal',
                    noWrap: true,
                  }}
                />
              </ListItem>
            ))}
          </React.Fragment>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} /> {/* This pushes the help section to the bottom */}
      <List>
        <Typography variant="caption" sx={{
          color: theme.palette.text.secondary,
          fontWeight: 'bold',
          pl: 1.5,
          pt: 1,
          pb: 0.5,
          display: 'block',
        }}>
          Help
        </Typography>
        {helpItems.map((item) => (
          <ListItem
            key={item.text}
            component={item.external ? 'a' : Link}
            to={!item.external ? item.path : undefined}
            href={item.external ? item.path : undefined}
            target={item.external ? "_blank" : undefined}
            rel={item.external ? "noopener noreferrer" : undefined}
            onClick={isMobile ? handleDrawerToggle : undefined}
            sx={{
              py: 0.5,
              minHeight: 36,
              px: 1.5,
              color: theme.palette.text.primary,
              textDecoration: 'none',
              '&:hover': { bgcolor: theme.palette.action.hover },
              '&:visited': {
                color: theme.palette.text.primary,
              },
            }}
          >
            <ListItemIcon sx={{
              color: theme.palette.text.primary,
              minWidth: 32,
            }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                fontSize: '0.875rem',
                fontWeight: 'normal',
                noWrap: true,
              }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box sx={{
        p: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <IconButton size="small" onClick={handleNotificationClick} color="inherit">
          <NotificationsIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={handleThemeChange} color="inherit">
          {darkMode ? <LightModeIcon fontSize="small" /> : <DarkModeIcon fontSize="small" />}
        </IconButton>
        <UserMenu
          handleMenu={handleMenu}
          handleClose={handleClose}
          handleLogout={handleLogout}
          anchorEl={anchorEl}
        />
      </Box>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: SIDEBAR_WIDTH },
        flexShrink: { sm: 0 },
      }}
    >
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={isMobile}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: SIDEBAR_WIDTH },
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: SIDEBAR_WIDTH },
          }}
          open
        >
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
