import React from 'react';
import { Grid, Paper, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DashboardSummary = ({ counts, loading }) => {
  const theme = useTheme();

  const renderCount = (count) => {
    return loading ? (
      <CircularProgress size={24} thickness={4} />
    ) : (
      <Typography component="p" variant="h6" color="text.primary" noWrap>
        {count}
      </Typography>
    );
  };

  return (
    <Grid container spacing={2} sx={{ width: '100%', margin: 0 }}>
      {Object.entries(counts).map(([key, value]) => (
        <Grid item xs={12} sm={6} md={4} lg={2.4} key={key} sx={{ padding: '8px !important' }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 100,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              bgcolor: theme.palette.background.paper,
              overflow: 'hidden',
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography component="h2" variant="subtitle2" color="text.primary" noWrap align="center">
              {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
            </Typography>
            {renderCount(value)}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardSummary;
