/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Paper, Grid, Chip, useTheme, CircularProgress,
  Accordion, AccordionSummary, AccordionDetails, IconButton, Tooltip
} from '@mui/material';
import { fetchWithAuth } from '../utils/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  AccessTime as AccessTimeIcon,
  Assignment as AssignmentIcon,
  Business as BusinessIcon,
  Category as CategoryIcon
} from '@mui/icons-material';

function ExecutionDetails() {
  const [execution, setExecution] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    fetchExecutionDetails();
  }, [id]);

  const fetchExecutionDetails = async () => {
    try {
      setLoading(true);
      const data = await fetchWithAuth(`/execution/${id}`);
      if (data) {
        setExecution(data);
      } else {
        setError('No data received from the server');
      }
    } catch (error) {
      setError(`Error fetching execution details: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!execution) {
    return <Typography color="text.primary">No execution data found.</Typography>;
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const calculateProcessingTime = (start, end) => {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    const diff = endTime - startTime;
    return `${(diff / 1000).toFixed(2)} seconds`;
  };

  const StatusLabel = ({ status }) => {
    const isSuccess = status.toLowerCase() === 'success';
    return (
      <Chip
        label={isSuccess ? 'Success' : 'Failed'}
        color={isSuccess ? 'success' : 'error'}
        variant="outlined"
        size="small"
        sx={{
          fontSize: '0.75rem',
          fontWeight: 500,
          '& .MuiChip-label': {
            padding: '0 6px',
          }
        }}
      />
    );
  };

  const renderJsonContent = (content) => {
    return (
      <pre style={{
        color: theme.palette.text.secondary,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        fontSize: '0.875rem',
        maxHeight: '400px',
        overflow: 'auto'
      }}>
        {JSON.stringify(content, null, 2)}
      </pre>
    );
  };

  const DetailItem = ({ icon, label, value }) => (
    <Box display="flex" alignItems="center" mb={2}>
      {icon}
      <Box ml={2}>
        <Typography variant="body2" color="text.secondary">{label}</Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ p: 3, maxWidth: '1200px', margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
          Execution Details
        </Typography>
        <Tooltip title="Back to Executions">
          <IconButton onClick={() => navigate('/executions')} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Paper sx={{ p: 3, mb: 3, bgcolor: theme.palette.background.paper, borderRadius: '8px', boxShadow: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DetailItem
              icon={<CategoryIcon color="primary" />}
              label="Status"
              value={<StatusLabel status={execution.execution_status} />}
            />
            <DetailItem
              icon={<AccessTimeIcon color="primary" />}
              label="Processing Time"
              value={calculateProcessingTime(execution.execution_start_time, execution.created_at)}
            />
            <DetailItem
              icon={<AccessTimeIcon color="primary" />}
              label="Start Time"
              value={formatDate(execution.execution_start_time)}
            />
            <DetailItem
              icon={<AccessTimeIcon color="primary" />}
              label="End Time"
              value={formatDate(execution.created_at)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DetailItem
              icon={<AssignmentIcon color="primary" />}
              label="Execution ID"
              value={execution.execution_id}
            />
            <DetailItem
              icon={<AssignmentIcon color="primary" />}
              label="Processing ID"
              value={execution.processing_id}
            />
            <DetailItem
              icon={<BusinessIcon color="primary" />}
              label="Customer ID"
              value={execution.customer_id}
            />
            <DetailItem
              icon={<CategoryIcon color="primary" />}
              label="Processing Type"
              value={execution.processing_type}
            />
          </Grid>
        </Grid>
      </Paper>

      <Accordion sx={{ mb: 3, borderRadius: '8px', boxShadow: 3, '&:before': { display: 'none' } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderRadius: '8px 8px 0 0'
          }}
        >
          <Typography variant="h6">Execution Input</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {renderJsonContent(execution.execution_input)}
        </AccordionDetails>
      </Accordion>

      {execution.error_info && (
        <Accordion sx={{ borderRadius: '8px', boxShadow: 3, '&:before': { display: 'none' } }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              borderRadius: '8px 8px 0 0'
            }}
          >
            <Typography variant="h6">Error Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderJsonContent(execution.error_info)}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}

export default ExecutionDetails;
