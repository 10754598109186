import React from 'react';
import { Box } from '@mui/material';
import Sidebar from './Sidebar';
import { useTheme } from '@mui/material/styles';

const SIDEBAR_WIDTH = 200;

const Layout = ({
  children,
  darkMode,
  handleThemeChange,
  handleNotificationClick,
  handleMenu,
  handleClose,
  handleLogout,
  anchorEl,
  isMobile,
  notificationAnchorEl,
  handleNotificationClose
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Sidebar
        darkMode={darkMode}
        handleThemeChange={handleThemeChange}
        handleNotificationClick={handleNotificationClick}
        handleMenu={handleMenu}
        handleClose={handleClose}
        handleLogout={handleLogout}
        anchorEl={anchorEl}
        isMobile={isMobile}
        notificationAnchorEl={notificationAnchorEl}
        handleNotificationClose={handleNotificationClose}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { xs: '100%', sm: `calc(100% - ${SIDEBAR_WIDTH}px)` },
          backgroundColor: theme.palette.background.default,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
